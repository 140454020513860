
import { Link } from "react-router-dom";
import { Seo } from '../layouts/Seo';

const Culture = () => {
    return (
    <>

<Seo
        title="Saigon A.I. - Culture - The Technologies We Work With and Solutions We Offer"
        description="Work hard and give back.  We believe in supporting work life balance, community service and career development to grow a stronger, more cohesive team to help you with your projects."
        type="webapp"
      />

<div class="breadcrumb">
    <span class="caret">&gt;</span>
    <span class="link"><Link to="/about" onclick="load_page(this)" data-content="content/about">About Us</Link></span>
    <span class="caret">&gt;</span>
    <span class="current">Company Culture</span>
</div>
<h1>Our Working Culture</h1>

  
  <div class="row featurette">
    <div class="col-12 order-md-2">
        <h2 class="featurette-heading">Relationship. <span class="text-muted">Teamwork.</span></h2>
        <p class="lead">
        <img class="feature-image max-px-300 pull-left roundy" src="/img/foosball.jpg" alt="Saigon A.I. Company Culture: Work life balance."/>
        We believe in striking a balance between hard work and leisure, fostering a vibrant and dynamic workplace culture. Our team finds joy and rejuvenation through a variety of recreational activities, including basketball, badminton, foosball, karaoke and darts. These shared interests not only provide moments of fun and friendly competition but also serve as avenues for team bonding and collaboration. We also enjoy learning more about each other with pecha kucha style presentations on topics of interest. We believe that a supportive and engaging atmosphere contributes to the creativity and innovation that define our team at Saigon A.I.

        </p>
    </div>
  </div>


  <h2 class="featurette-heading">Work Hard. <span class="text-muted">Give Back.</span></h2>
  <div class="row featurette">
    <div class="col-md-7">
      <p class="lead">Our commitment to community extends beyond technological innovation, emphasizing the significance of giving back. Our company culture of compassion instills in our staff the importance of community engagement. We prioritize respect, impartarting the importance of understanding that "everyone is better at something than you." This creates an environment of mutual respect and appreciation, not just within company, but for the community at large. 
        <p>
          Through initiatives like our monthly, free I.T. English Club, we strive to bridge language gaps and provide valuable skills to students and young professionals. At Christmas, we decorate and distributing gingerbread cookies to our neighborhood. 
        </p>
        <p>
         Additionally, we recognize that individuals may have 'red fish' days—times when they may need support to regain their 'blue fish' (team player) status. Saigon A.I. encourages a supportive approach, teaching our staff how to assist colleagues in overcoming challenges and returning to a collaborative mindset.</p>
       </p>
    </div>
    <div class="col-md-5">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/SAIinfRphlI?si=ZHxFydBK6pPA9u47" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
  </div>

  <div class="py-3">&nbsp;</div>

    </>
    )
  };
  
  export default Culture;