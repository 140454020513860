export const pressData = [
  {
    type: "PODCAST",
    date: "23 July 2024",
    title: "Tectonic Lounge Ep. 11: Demystifying A.I. for Business People with Practical Insights from Andrew Schwabe, Saigon A.I.",
    img: "https://img.youtube.com/vi/6wlYunvuYFc/maxresdefault.jpg",
    links: [
      {"name": "Watch Podcast", "uri": "https://www.youtube.com/watch?v=6wlYunvuYFc"},
      {"name": "Listen on Spotify", "uri": "https://podcasters.spotify.com/pod/show/techtonic-lounge/episodes/E11-Demystifying-A-I--for-Business-People-with-Practical-Insights-from-Andrew-Schwabe--Saigon-AI-e2ma4t8"}
    ]
  },
  {
      type: "PRESS RELEASE",
      date: "8 March 2024",
      title: "Saigon A.I. Partners with Microsoft Code; Without Barriers to Drive Gender Inclusivity",
      img: "https://www.saigonai.com/img/ms_sgai_2024.jpg",
      links: [
        {"name": "PDF (English)", "uri": "/documents/saigonai_microsoft_press_release_EN.pdf"},
        {"name": "PDF (Tiếng Việt)", "uri": "/documents/saigonai_microsoft_press_release_VN.pdf"}
      ]
    },
    {
        type: "PODCAST",
        date: "17 January 2024",
        title: "Asia Tech Podcast EP 310: Rebecca Schwabe – Founder at Saigon A.I. – Pioneering Data Science and AI Innovation in Vietnam",
        img: "https://www.saigonai.com/img/Rebecca-Schwabe-Website-01.png",
        links: [
          {"name": "Watch Podcast", "uri": "https://asiatechpodcast.com/2024/01/17/4447/"}
        ]
      },
      {
        type: "POST",
        date: "21 November 2023",
        title: "Amcham ESG Awards: Saigon A.I. Recognized for Social Impact",
        img: "https://media.licdn.com/dms/image/D5610AQEaVZ2mOJSgOQ/image-shrink_800/0/1701256225564?e=2147483647&v=beta&t=YzMoUPSq-H_BuLAgla0gXpMyOSrhzx5r6GA2DWhtdAw",
        links: [
          {"name": "View on Linked-In", "uri": "https://www.linkedin.com/posts/saigonai_saigonai-thanksgivingcheers-gratitude-activity-7135585894564036608-qj00"}
        ]
      },
      {
        type: "VIDEO",
        date: "23 September 2023",
        title: "@America Jakarta: Empowering Women in Tech: Breaking Barriers, Building Success",
        img: "/img/20230922_atamerica_jakarta.jpg",
        links: [
          {"name": "Watch on YouTube", "uri": "https://www.youtube.com/watch?v=hyx2sZRFHpE"}
        ]
      },
      {
        type: "PODCAST",
        date: "15 August 2022",
        title: "Asia Insurtech EP 181: Andrew Schwabe – People Are Starting to Wake Up and Say “Service Matters",
        img: "https://www.saigonai.com/img/AndrewSchwabe.jpg",
        links: [
          {"name": "Watch Podcast", "uri": "https://asiainsurtechpodcast.com/ep-181-andrew-schwabe-founder-chairman-at-formotiv-people-are-starting-to-wake-up-and-say-service-matters/"}
        ]
      },
      {
        type: "ARTICLE",
        date: "21 April 2021",
        title: "YORK.IE: Meet the Women Data Analysts Who Are Smashing Stereotypes in Vietnam",
        img: "/img/saigon_ai_team_york_ie.png",
        links: [
          {"name": "Read Article", "uri": "https://fuel.york.ie/news/data-ai/women-data-analysts-smashing-stereotypes-vietnam"}
        ]
      },
      {
        type: "PODCAST",
        date: "26 November 2021",
        title: "FUSE EP.169: Building up an irresistible culture to reach & surpass product-market fit- Andrew Schwabe",
        img: "https://img.youtube.com/vi/SAIinfRphlI/maxresdefault.jpg",
        links: [
          {"name": "Watch Podcast", "uri": "https://www.youtube.com/watch?v=SAIinfRphlI"}
        ]
      },
      {
        type: "ARTICLE",
        date: "8 October 2021",
        title: "Forbes: Design The Next Stages Of Your Business With These Top Technology And AI Innovators",
        img: "https://www.saigonai.com/img/forbes.png",
        links: [
          {"name": "Read Article", "uri": "https://www.forbes.com/sites/rebeccasuhrawardi/2021/10/08/thinking-about-ai-to-grow-your-fashion-or-design-brand-let-these-companies-inspire-you/"}
        ]
      },
    ]